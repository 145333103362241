import React, { Fragment, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

// images
import banner2 from "../../doc/img/bg/sidebar-1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getArticleById,
  getArticles,
  getArticlesByCategory,
} from "../../store/actions";
import { Interweave } from "interweave";
import CategoriesWidget from "../../components/CategoriesWidget";
import Moment from "moment";
import PreviewRelated from "../../components/PreviewRelated";

const PostOnePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);
  const article = useSelector((state) => state.article.article);
  const articlesForCategory = useSelector(
    (state) => state.article.articlesForCategory
  );
  const currentArticleIndex = articlesForCategory && article ? articlesForCategory.findIndex((el) => el.id === article.id) : '' 
  const categories = useSelector((state) => state.options.categories);
  const options = useSelector((state) => state.options.options);

  useEffect(() => {
    dispatch(getArticles());
    dispatch(getArticleById(id));
  }, [id]);

  useEffect(() => {
    dispatch(getArticlesByCategory(article?.category.id));
  }, [article]);

  return (
    <Fragment>
      <div className="archives post post1">
        <span className="space-30" />
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-8">
              <div className="row">
                <div className="col-lg-12">
                  <div className="article__category-big">
                    {article?.category.title}
                  </div>
                </div>
                <div className="col-6 text-right">
                  {/* <div className="page_comments">
                                        <ul className="inline">
                                            <li><FontAwesome name="comment"/>563</li>
                                            <li><FontAwesome name="fire"/>563</li>
                                        </ul>
                                    </div> */}
                </div>
              </div>
              <div className="space-30" />
              <div className="single_post_heading">
                <h1>{article?.title}</h1>
              </div>
              <hr className="divide-block-line"></hr>
              <div className="author_date-wrapper">
                <div className="author_left">
                <div className="article_author">
                  <Link to={`/article/${article?.id}`}>
                    <img
                      src="https://secure.gravatar.com/avatar/c76d5eef414fbb830e0c816793d1f7ef?s=32&d=mm&r=g"
                      alt=""
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                      width={24}
                      height={24}
                    />
                    {article?.author + ","}{" "}
                  </Link>
                </div>
                <div style={{ width: "5px" }}></div>
                <div className="date">
                  <Link to={`/article/${article?.id}`}>
                    {Moment(article?.created).format("DD.MM.YYYY")}
                  </Link>
                </div>
                </div>
                <div className="author_right">
                <div className="share_block">
                  TEILEN AUF:
                  <img src="/facebook-16.png" alt="facebook share" />
                  <img src="/twitter-16.png" alt="twitter share" />
                  <img src="/pinterest-16.png" alt="pinterest share" />
                </div>
                </div>
              </div>
              <div className="space-40" />
              <img
                src={article?.image?.url}
                width={750}
                height={450}
                alt="thumb"
              />
              <div className="space-20" />
              <div className="row">
                <div className="align-self-center"></div>
                <div className="col-lg-6 align-self-center"></div>
              </div>
              <div className="space-20" />
              {!article?.article && !token ? (
                <>
                  <div>{article?.description}</div>
                  <div className="subscribe-text">
                    <h1>Kostenlos registrieren und weiterlesen.</h1>
                    <p>
                      Nach der Registrierung haben Sie unter anderem folgende
                      Vorteile:
                    </p>
                    <ul>
                      <li>Unabhängig recherchierte Artikel</li>
                      <li>Das Wichtigste des Tages</li>
                      <li>Persönliche Leseempfehlungen</li>
                      <li>Newsletter</li>
                      <li>Kommentarfunktion</li>
                    </ul>
                    <div
                      className="sign-in-button small"
                      onClick={() => history.push("/login")}
                    >
                      Sign in
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {!article?.article && token && article?.state === "PRIVATE" ? (
                <>
                  <div>{article?.description}</div>
                  <div className="subscribe-text">
                    <h1>Kostenlos registrieren und weiterlesen.</h1>
                    <p>
                      Nach der Registrierung haben Sie unter anderem folgende
                      Vorteile:
                    </p>
                    <ul>
                      <li>Unabhängig recherchierte Artikel</li>
                      <li>Das Wichtigste des Tages</li>
                      <li>Persönliche Leseempfehlungen</li>
                      <li>Newsletter</li>
                      <li>Kommentarfunktion</li>
                    </ul>
                    <div
                      className="sign-in-button"
                      style={{ textAlign: "center" }}
                      onClick={() => history.push("/subscribe")}
                    >
                      Subscribe
                    </div>
                  </div>
                </>
              ) : (
                <div className="article__container">
                  <Interweave content={article?.article} />
                </div>
              )}
              <div className="space-40" />
              <div className="space-40" />
              <hr className="divide-block-line-thiner"></hr>
              <div className="block-title">
              <div className="row justify-content-between">
              {currentArticleIndex === 0 && (
                          <div className="col-sm-6 noppading">
                          <div className="next-prev-container container-left d-flex flex-row">
                            </div>
                          </div>
                        )}
                  {articlesForCategory &&
                    articlesForCategory?.map((e, i) => (
                      <>
                        {i === (currentArticleIndex - 1 ) && (
                          <div className="col-sm-6 noppading" onClick={() => (dispatch(getArticleById(e.id), history.push(`${e.id}`)))}>
                            <div className="next-prev-container container-left d-flex flex-row">
                            <div className="next-prev-container-arrow mr-2">
                                <img src="/arrow-89-64.png" width={64} height={64} alt="back" />
                              </div>
                              <div className="next-prev-container-title d-flex flex-column justify-content-center">
                                <p className="next-prev-text">
                                  {"vorheriger Beitrag".toUpperCase()}
                                </p>
                                <p className="next-prev-title">{e.title}</p>
                              </div>
                            </div>
                          </div>
                        )} 
                        {i === (currentArticleIndex + 1 ) && (
                          <div className="col-sm-6 noppading 2" onClick={() => dispatch(getArticleById(e.id), history.push(`${e.id}`))}>
                            <div className="next-prev-container container-right d-flex flex-row justify-content-end">
                              <div className="next-prev-container-title d-flex flex-column justify-content-center">
                                <p className="next-prev-text">
                                  {"nächster Beitrag".toUpperCase()}
                                </p>
                                <p className="next-prev-title">{e.title}</p>
                              </div>
                              <div className="next-prev-container-arrow mr-2">
                                <img src="/arrow-24-64.png" width={64} height={64} alt="next" />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
              <hr className="divide-block-line-thin"></hr>
              <div className="author_block">
                <div className="author_block-avatar">
                  <img
                    src="https://secure.gravatar.com/avatar/c76d5eef414fbb830e0c816793d1f7ef?s=200&d=mm&r=g"
                    width={100}
                    height={100}
                    alt="author avatar"
                  />
                </div>
                <div className="author_block-name">
                  <p className="author_block-name-byWho">Über den Autor</p>
                  <p className="author_block-name-author">{article?.author}</p>
                </div>
              </div>
              <hr className="divide-block-line"></hr>
              <div className="block-title">
                <h1>ÄHNLICHE BEITRÄGE</h1>
                <div className="row">
                  {articlesForCategory &&
                    articlesForCategory?.slice(0, 3).map((e) => (
                      <div className="col-sm-4 col-md-4">
                        <PreviewRelated item={e} />
                      </div>
                    ))}
                </div>
              </div>
              <div className="space-40" />
              {/* <PostOnePagination/> */}
            </div>
            <div className="col-md-6 col-lg-4">
              <CategoriesWidget categories={categories} articlePage />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PostOnePage;
