import React, {Fragment, useEffect} from 'react';
import TrendingNews from "../../components/TrendingNews";
import CategoriesWidget from "../../components/CategoriesWidget";
import Category from '../../components/Category';
import {useDispatch, useSelector} from 'react-redux';
import {getArticles, setArticles, setCategories} from '../../store/actions';
import CategorySmall from '../../components/CategorySmall';
import {groupArr} from '../../utils/commonFunctions';
import FirstCategory from '../../components/FirstCategory';
import CategoryLarge from '../../components/CategoryLarge';

const HomePage = () => {
    const dispatch = useDispatch()
    const categories = useSelector(state => state.article.categories);
    const articles = useSelector((state) => state.article.articleList);
    const categoryforNewsBlock = categories[0];

    const groupArray = groupArr(categories.slice(1, categories.length), [])
    useEffect(() => {
        dispatch(setArticles([]))
        dispatch(setCategories([]))
        dispatch(getArticles())
    }, [])


    return (
        <Fragment>
            {/* <FeatureNews/> */}
            {categoryforNewsBlock ? (
                <div className="container">
                <div className="row">
                    <div className='first-block-line'></div>
                    <div className="col-sm-7 col-md-8" id="big-container">
                        <TrendingNews/>
                    </div>
                    <div className="col-sm-5 col-md-4 top-line" id="small-container">
                        <hr className='divide-block-line'></hr>
                        <FirstCategory category={categoryforNewsBlock}/>
                    </div>
                </div>
            </div>
            ) : ''}
            {groupArray && groupArray?.map((e, i) => (
                <div className="container" key={e + i}>
                    <div className="row">
                        <div className="col-sm-7 col-md-8" id="big-container">
                        <hr className='divide-block-line'></hr>
                            <Category key={i + 'category'} category={e[0]}/>
                        </div>
                        <div className="col-sm-5 col-md-4" id="small-container">
                        <hr className='divide-block-line'></hr>
                            {e[1] ? <CategorySmall key={i + 'small'} category={e[1]}/> : ''}
                        </div>
                        <div className="col-sm-12" id="large-container">
                        <hr className='divide-block-line'></hr>
                            {e[0] ? <CategoryLarge key={i + "large"} category={e[0]} /> : ''}
                        </div>
                    </div>
                </div>
            ))}
            <div className="space-70"/>
            {/* {categories.length > 0 ? (
                <div className="entertrainments">
                    <div className="container">
                        <div className="row">
                                <div className="row-bottom">
                                        <CategoriesWidget categories={categories}/>
                            </div>
                        </div>
                    </div>
                </div
            ) : ''} */}
        </Fragment>
    );
};

export default HomePage;