import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { setToken } from "../../api";
import { confirmEmail } from "../../api/Auth";

export const ConfirmEmailSuccess = () => {
  const history = useHistory();
  const {token} = useParams()

  const Confirm = async (values) => {
    await confirmEmail(values);
  };

  const formik = useFormik({
    initialValues: {
      token: token
    },
    onSubmit: () => {
      Confirm(values);
      history.push("/login");
    },
  });

  const { values, handleSubmit, handleChange } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="container" style={{height: "250px", marginTop: "5%"}}>
            <h1 style={{marginBottom: "1%"}}>Email confirmation</h1>
            <p style={{marginBottom: "3%"}}>To finally confirm your email click on this button and login with new credentials</p>
              <button className="sign-in-button" type="submit">Confirm and Sign in</button>
          </div>
      </Form>
    </FormikProvider>
  );
};
