import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSubscribe } from "../../api/Subscription";
import { getSubscriptions, setSubscriptions } from "../../store/actions";
import FontAwesome from "../uiStyle/FontAwesome";

export const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(
    (state) => state.subscription.subscriptions
  );

  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState();
  const [count, setCount] = useState(1);
  const [slide, setSlide] = useState(`slide-${count}`);

  const onArrowClick = (sign) => {
    const spreadSubs = [...subscriptions];
    if (sign === "+") {
      const sliders = [spreadSubs.pop(), ...spreadSubs].map((e) => e.slide);
      subscriptions.forEach((sub, i) => {
        sub.slide = sliders[i];
      });

      const filteredSubTypes = ["slide-1", "slide-2", "slide-3"].map(
        (slide) => {
          return subscriptions.find((sub) => sub.slide === slide);
        }
      );
      setSelectedSubscriptionType(
        filteredSubTypes.map((subType) => subType.subscriptionTypes)
      );
      dispatch(setSubscriptions(subscriptions));
    } else {
      const slideOne = spreadSubs.shift();
      const sliders = [...spreadSubs, slideOne].map((e) => e.slide);
      subscriptions.forEach((sub, i) => {
        sub.slide = sliders[i];
      });

      const filteredSubTypes = ["slide-1", "slide-2", "slide-3"].map(
        (slide) => {
          return subscriptions.find((sub) => sub.slide === slide);
        }
      );
      setSelectedSubscriptionType(
        filteredSubTypes.map((subType) => subType.subscriptionTypes)
      );
      dispatch(setSubscriptions(subscriptions));
    }
  };

  const onPuchaseClick = async (stripePriceId) => {
    console.log("STRIPEPRICEID", stripePriceId);
    const values = {
      cancelUrl: "https://zyu.ch/subscribe",
      priceId: stripePriceId,
      successUrl: "https://zyu.ch/profile",
    };
    const res = await userSubscribe(values);
    window.location.replace(res);
  };

  const onPeriodChoose = (subscriptionTypeId, price) => {
    subscriptions.forEach((subscr) => {
      const subscriptionType = subscr.subscriptionTypes.find(
        (el) => el.id === subscriptionTypeId
      );

      if (subscriptionType) {
        subscriptionType.prices.forEach((pr) => {
          if (pr.period === price.period) {
            pr.selected = true;
          } else {
            pr.selected = false;
          }
        });
      }
    });
    setSubscriptions(subscriptions);
    const filteredSubTypes = ["slide-1", "slide-2", "slide-3"].map((slide) => {
      return subscriptions.find((sub) => sub.slide === slide);
    });
    setSelectedSubscriptionType(
      filteredSubTypes.map((subType) => subType.subscriptionTypes)
    );
    setSelectedPrice(price);
  };

  const setImagesForCards = (element) => {
    if(element.title === "Digital Plus") {
      return (<img
        src="http://cdn.onlinewebfonts.com/svg/img_486426.png"
        height={80}
      />)
    } else if(element.title === "Digital Max") {
      return ( <img
        src="http://cdn.onlinewebfonts.com/svg/img_476855.png"
        height={80}
      />)
    } else return (<img
      src="https://pic.onlinewebfonts.com/svg/img_495739.png"
      height={80}
    />)
  }

  useEffect(() => {
    dispatch(getSubscriptions());
  }, []);

  useEffect(() => {
    setSlide(`slide-${count}`);
  }, [count]);

  useEffect(() => {
    if (!selectedSubscriptionType.length)
      setSelectedSubscriptionType(
        subscriptions.map((e, i) => e.subscriptionTypes)
      );
  }, [subscriptions]);

  return (
    <div className="container">
      <div className="row">
        <div
          className="widget-title d-flex justify-content-center"
          style={{ flexGrow: 1 }}
        >
          Choose subscription plan
        </div>
        <div className="subscriptions__container">
          <div className="slider_arrow-left" onClick={() => onArrowClick("+")}>
            <FontAwesome name="chevron-left" />
          </div>
          <div className="slider_arrow-right" onClick={() => onArrowClick("-")}>
            <FontAwesome name="chevron-right" />
          </div>
          {subscriptions.length > 0 &&
            subscriptions.map((e, i) => (
              <div
                className={`subscriptions__view-access col-md-4 slide ${e.slide}`}
                onClick={() => i === 2 ? onArrowClick("+") : onArrowClick("-")}
                key={e.id + count + i}
              >
                <>
                  {setImagesForCards(e)}
                  {e.title}
                </>
              </div>
            ))}
        </div>
        <div className="subscriptions__list">
          {selectedSubscriptionType && selectedSubscriptionType[1]
            ? selectedSubscriptionType[1].map((e) => (
                <div className="subscription_type-item" key={e.id}>
                  <div className="subscription_type-title">{e.title}</div>
                  <div className="subscription_type-image">
                    <img width={300} height={200} src={e.image} alt="123" />
                  </div>
                  {/* <div className="subscription_type-title">{e.period}</div> */}
                  <div className="subscription_type-price-container">
                    {e.prices.map((price) => (
                      <div
                        className={`subscription_type-price ${
                          price.selected ? "selected-price" : ""
                        }`}
                        onClick={() => onPeriodChoose(e.id, price)}
                        key={price.id}
                      >
                        <div className="icon_container">
                          {price.selected ? (
                            <FontAwesome name="check-circle fa-lg" />
                          ) : (
                            <FontAwesome name="circle-thin fa-lg" />
                          )}
                        </div>
                        <div className={`subscription_type-price-period`}>
                          {price.period}
                        </div>
                        <div className={`subscription_type-price-monthlyamount`}>
                          {(price.amount / 12 / 100).toFixed(2) + '\n' + 'per month'}
                        </div>
                      </div>
                    ))}
                  </div>
                  {e.prices.map((price) => (
                    <>
                      <div
                        className={`subscription_type-price-amount p-5 ${
                          price.selected ? "" : "d-none"
                        }`}
                      >
                        {price.selected
                          ? price.amount / 100 + '\n' + price.currency
                          : ""}
                      </div>
                      <button
                        className={`purchase-button ${
                          price.selected ? "" : "d-none"
                        }`}
                        onClick={() => onPuchaseClick(price.stripePriceId)}
                      >
                        Purchase
                      </button>
                    </>
                  ))}
                  <p className="subscription_features-title">
                    Features:
                  </p>
                  <ul className="subscription_features-list">
                    {e.categories.map((cat) => (
                      <li>{cat.title}</li>
                    ))}
                  </ul>
                </div>
              ))
            : "Currently we have no subscriptions available"}
        </div>
      </div>
    </div>
  );
};
