import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Input } from "reactstrap";
import { forgot } from "../../api/Auth";

export const ForgotPage = () => {

  const history = useHistory()

  const forgot1 = async (values) => {
    await forgot(values) 
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      url: "https://zyu.ch/new-password"
    },
    onSubmit: () => {
      forgot1(values);
      history.push("/confirm")
    },
  });

  const { values, handleSubmit, handleChange } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="container">
          <div className="column mb-3">
            <div className="col-lg-8">
              <label>Email</label>
              <Input id="email" type="email" value={values.email} onChange={handleChange} />
            </div>
            <div className="col-lg-12">
              <button className="sign-in-button" type="submit">Send an email</button>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
