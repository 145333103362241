import {IS_LOADING, RE_RENDERED, THROW_ERROR, THROW_SUCCESS,   SET_ARTICLES, SET_CATEGORIES, SET_ARTICLE_BY_ID, STORE_NAME_RESET, SET_ARTICLES_BY_CATEGORY} from "../constants";
import {toast} from "react-toastify";

const init = {
    isLoading: false,
    error: '',
    success: '',
    article: null,
    articleList: [],
    articlesForCategory: [],
    categories: [],
    reRendered: false
};

const articleReducer = (state = init, action) => {
    switch (action.type) {
        case THROW_ERROR: {
            toast.error(action.payload);
            return {
                ...state,
                error: action.payload
            }
        }
        case THROW_SUCCESS: {
            toast.success(action.payload);
            return {
                ...state,
                success: action.payload
            }
        }
        case STORE_NAME_RESET: {
            return {...init}
        }
        case SET_ARTICLES: {
            return {
                ...state,
                articleList: action.payload
            }
        }
        case SET_ARTICLE_BY_ID: {
            return {
                ...state,
                article: action.payload
            }
        }
        case SET_CATEGORIES: {
            return {
                ...state,
                categories: action.payload
            }
        }
        case SET_ARTICLES_BY_CATEGORY: {
            return {
                ...state,
                articlesForCategory: action.payload
            }
        }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case RE_RENDERED:
            return {
                ...state,
                reRendered: action.payload
            };
        default:
            return state;
    }
};

export default articleReducer;