import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import {Collapse} from "reactstrap";

import './style.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const SidebarMenu = ({menus, sideShow, setSideShow, className}) => {
    const [sMenu, setSMenu] = useState(null);
    const [stMenu, setSTMenu] = useState(null);
    
    return (
        <div className={`sidebarMenu ${sideShow ? '' : 'hideSideMenu'} ${className ? className : ''}`}>
            <span className="clox" onClick={() => setSideShow(false)}>Close</span>
            <ul className="navBar">
                {menus.length > 0 ? menus.map((item, i) => (
                    <li key={i}
                        className={`${item.child ? 'has_sub' : ''}`}>
                            <NavLink exact onClick={item.action} className={sMenu === item.id ? 'active' : ''}
                                       to={item.link}>{item.linkText}</NavLink>
                    </li>
                )) : null}
            </ul>
        </div>
    );
};

export default SidebarMenu;