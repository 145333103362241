import {
  SET_ARTICLES,
  SET_CATEGORIES,
  SET_ARTICLE_BY_ID,
  IS_LOADING,
  THROW_ERROR,
  SET_SUBSCRIPTIONS,
  SET_USER,
  STORE_NAME_RESET,
  SET_TOKEN,
  SET_ARTICLES_BY_CATEGORY,
  SET_OPTIONS,
  SET_CATEGORIES_FETCH_DATA
} from "../constants";
import { getAll, getArticlesListByCategory, getById } from "../../api/Article";
import { getAll as getAllSubscriptions } from "../../api/ViewAccessSubscription"
import { getInfoByToken } from "../../api/User";

export const getArticles = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await getAll();
      dispatch(setArticles(res));
      let categories = [...new Set(res.map((e) => e.category.title))];
      dispatch(setCategories(categories));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(e));
      dispatch(setLoading(false));
    }
  };
};

export const getArticleById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await getById(id);
      dispatch(setChosenArticle(res));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(e));
      dispatch(setLoading(false));
    }
  };
};

export const getArticlesByCategory = (categoryId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      // dispatch(setArticles([]));
      const res = await getArticlesListByCategory({ categoryId });
      dispatch(setArticlesForCategory(res));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(e));
      dispatch(setLoading(false));
    }
  };
};

export const getSubscriptions = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(setSubscriptions([]));
      const res = await getAllSubscriptions();
      dispatch(setSubscriptions(res.map((e, i) => {
        e.slide = `slide-${i + 1}`;
        e.subscriptionTypes.forEach(el => {
          el.prices.forEach(price => price.period === "MONTH" ? price.selected = false : price.selected = true )
        })
        return e;
      })));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(e));
      dispatch(setLoading(false));
    }
  };
}

export const getUserInfo = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUser({}));
      const res = await getInfoByToken();
      dispatch(setUser(res));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(e));
      dispatch(setLoading(false));
    }
  };
}

export const setArticles = (articles) => ({
  type: SET_ARTICLES,
  payload: [...articles],
});

export const setOptions = (options) => ({
  type: SET_OPTIONS,
  payload: options,
});

export const setArticlesForCategory = (articles) => ({
  type: SET_ARTICLES_BY_CATEGORY,
  payload: [...articles]
})

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setTokenToStore = (token) => ({
  type: SET_TOKEN,
  payload: token
})

export const setSubscriptions = (subscriptions) => ({
  type: SET_SUBSCRIPTIONS,
  payload: [...subscriptions],
});

const setChosenArticle = (article) => ({
  type: SET_ARTICLE_BY_ID,
  payload: article,
});

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: [...categories],
});

export const setCategoriesFetchData = (categories) => ({
  type: SET_CATEGORIES_FETCH_DATA,
  payload: [...categories],
});


const setLoading = (loading) => ({
  type: IS_LOADING,
  payload: loading,
});

export const resetStore = () => ({
  type: STORE_NAME_RESET
});

export const setError = (error) => ({
  type: THROW_ERROR,
  payload: error,
});
