import { post, get, put, deletion } from './index';

export const create = (values) => {
  return new Promise((resolve, rej) => {
    post('api/user/create', values)
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e);
      });
  });
};

export const update = (values) => {
  return new Promise((resolve, rej) => {
    put('api/user/update', values)
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e.response.data.message);
      });
  });
};

export const getById = (id) => {
  return new Promise((resolve, rej) => {
    get(`api/user/${id}`)
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e.response.data.message);
      });
  });
};

export const getInfoById = (id) => {
  return new Promise((resolve, rej) => {
    get(`api/user/info/${id}`)
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e.response.data.message);
      });
  });
};

export const getInfoByToken = () => {
  return new Promise((resolve, rej) => {
    get(`api/user/info`)
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e.response.data.message);
      });
  });
};

export const getAll = () => {
  return new Promise((resolve, rej) => {
    get('api/user/list')
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e.response.data.message);
      });
  });
};

export const remove = (id) => {
  return new Promise((resolve, rej) => {
    deletion(`api/user/${id}`)
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e.response.data.message);
      });
  });
};

export const giftSubscription = (values) => {
  return new Promise((resolve, rej) => {
    post('api/user_subscription/create', values)
      .then((res) => resolve(res.data))
      .catch((e) => {
        rej(e.response.data.message);
      });
  });
};
