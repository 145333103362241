import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Input } from "reactstrap";
import { getToken, setToken } from "../../api";
import { login } from "../../api/Auth";
import { setTokenToStore } from "../../store/actions";
import { useDispatch } from "react-redux";
import { Paper, TextField } from "@mui/material";
import Heading from "../../components/uiStyle/Heading";

export const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const Login = async (values) => {
    const token = await login(values);
    dispatch(setTokenToStore("Bearer " + token.accessToken));
    setToken("Bearer " + token.accessToken);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      await Login(values);
      history.push("/");
    },
  });

  const { values, touched, errors, handleSubmit, handleChange } = formik;
  return (
    <div className="container">
      <FormikProvider value={formik}>
        <Heading title="Login" />
        <h1
          style={{
            color: "rgb(102, 102, 102)",
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: "2%",
          }}
        >
          Enter your details below
        </h1>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="column" style={{ marginBottom: "5%" }}>
            <div className="col-lg-4">
              <TextField
                sx={{ width: "100%", marginBottom: "6%" }}
                id="email"
                type="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
              />
              {touched.email && errors.email ? (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  Wrong format of email
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-4">
              <TextField
                sx={{ width: "100%", marginBottom: "2%" }}
                id="password"
                type="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
              />
              {touched.password && errors.password ? (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  Password required
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex column col-lg-4 my-2">
              <button
                className="sign-in-button ml-3"
                style={{ width: "100%" }}
                type="submit"
              >
                Sign In
              </button>
              <div
                className="my-2 ml-3"
                style={{ textAlign: "center", width: "100%" }}
              >
                or
              </div>
              <button
                className="sign-in-button ml-3"
                style={{ width: "100%" }}
                onClick={() => history.push("/register")}
              >
                Register
              </button>
            </div>
            <div className="col-lg-12">
              Don't remember your password? - {"\n"}
              <a className="forgot-button" href="/forgot">
                Forgot Password
              </a>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};
