import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useFormik } from "formik";
import Heading from "../../components/uiStyle/Heading";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { getUserInfo } from "../../store/actions";
import { LoginPage } from "../LoginPage";

export const SubscriptionProfileView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: () => {
      history.push("/");
    },
  });

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const { values, handleSubmit, handleChange, setValues } = formik;

  return (
    <div className="container">
      <div className="column mb-3">
        <div className="col-lg-12">
          {user?.email ? (
            <>
              <Heading title="My Subscription" />
                <TableContainer sx={{boxShadow: "1px 1px 10px 1px #666"}} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Plan</TableCell>
                      <TableCell align="left">Categories</TableCell>
                      <TableCell align="left">Period</TableCell>
                      <TableCell align="left">Date of subscription</TableCell>
                      <TableCell align="left">Due date</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.payments && user.payments.length ?
                      user.payments.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.userSubscription.title}
                          </TableCell>
                          <TableCell component="th">
                            {row.userSubscription.categories.map(
                              (e) => e.title + ";"
                            )}
                          </TableCell>
                          <TableCell component="th">
                            {row.userSubscription.period}
                          </TableCell>
                          <TableCell component="th">
                            {new Date(row.userSubscription.created)
                              .toISOString()
                              .split(".")[0]
                              .replace("T", " ")
                              .slice(0, -3)}
                          </TableCell>
                          <TableCell component="th">
                            {new Date(row.userSubscription.expired)
                              .toISOString()
                              .split(".")[0]
                              .replace("T", " ")
                              .slice(0, -3)}
                          </TableCell>
                          <TableCell component="th">
                            {row.userSubscription.created <
                            row.userSubscription.expired
                              ? "Active"
                              : "Unactive"}
                          </TableCell>
                        </TableRow>
                      )) : ["User have no subscription history"].map(emp => (
                        <TableRow key={"111"}> <TableCell component="th" scope="row">
                      {emp}
                    </TableCell></TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <LoginPage />
          )}
        </div>
      </div>
    </div>
  );
};
