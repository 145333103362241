import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import union from "../../doc/img/icon/union.png";
import { useDispatch, useSelector } from "react-redux";
import { getArticleById, getArticles } from "../../store/actions";
import { useState } from "react";

const CategoriesWidget = ({ categories, articlePage = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const articles = useSelector((state) => state.article.articleList);
  const [categoriesWithArticles, setCategoriesWithArticles] = useState([]);
  const getCategoryData = (category) => {
    const filtered = articles.filter((e) => e.category.title === category);
    return filtered[0];
  };

  const getCategoriesWithArticles = () => {
    return categories.map((cat) => ({
      ...cat,
      articlesCount: articles.filter((art) => art.category.title === cat.title)
        .length,
      articles: articles
        .filter((art) => art.category.title === cat.title)
        .slice(0, 3),
    }));
  };

  useEffect(() => {
    setCategoriesWithArticles(getCategoriesWithArticles());
  }, [categories, articles]);

  return (
    <div className="widget category mb30 mt60">
      <hr className="divide-block-line"></hr>
      <div className="row">
        <div className="col-6 align-self-center">
          <h2 className="widget-title">KATEGORIEN</h2>
        </div>
      </div>
      {articlePage ? (
        <ul>
          {categoriesWithArticles.map((item, i) => (
            <li key={i}>
              <hr className="divide-category-line"></hr>
              <div className="category_container">
                <div className="category_top-container">
                  <div
                    className="category_title"
                    onClick={() => (
                      history.push(`/category/${item.id}`),
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    )}
                  >
                    {item.title}
                  </div>
                  <div className="category_title-counter">
                    {item.articlesCount}
                  </div>
                </div>
                <div className="category_bottom-container">
                  {item.articles.map((art, ind) => (
                    <img
                      onClick={() => (
                        dispatch(getArticleById(art.id)),
                        history.push(`/article/${art.id}`),
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        })
                      )}
                      src={art.image.url ? art.image.url : union}
                      width={ind === 0 ? 194 : 78}
                      height={75}
                      alt={"art.img"}
                    />
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="categories_container">
          {categories.map((item, i) => (
            <div className="category1" key={i}>
              <Link
                to={`/category/${getCategoryData(item)?.category.id}`}
                style={{
                  background: `url(${getCategoryData(item)?.image?.url})`,
                }}
              >
                {" "}
                <span>{item}</span>
                <img src={union} alt="category" />
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoriesWidget;
