import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import Moment from 'moment';


const PreviewSmall = ({item, categoryFirst}) => {
    return (
        <Fragment>
            <div className="space-20 thin-line-dotted"/>
            <div className="single_post widgets_small">
                <div className="post_img">
                    <div className="img_wrap">
                        {categoryFirst ? <img src={item.image?.url} width={80} height={80} alt="thumb"/> : <img src={item.image?.url} width={80} height={80} alt="thumb"/> }
                    </div>
                </div>
                <div className="single_post_text">
                    <Link to={`/article/${item.id}`}>
                        <h4 className="title-small pointer">
                            {item.title.toUpperCase()}
                        </h4>
                    </Link>
                    <div className="author_date-wrapper">
                        <div className="article_author-small">
                            <Link to={`/article/${item.id}`}>{item.author + ", "}</Link>
                        </div>
                        <div style={{width: "5px"}}></div>
                        <div className="date-small">
                            <Link to={`/article/${item.id}`}>{Moment(item.created).format('DD.MM.YYYY')}</Link>
                        </div>
                    </div>
                <div className="space-20"/>
                </div>
            </div>
            <div className="space-20"/>
        </Fragment>
    );
};

export default PreviewSmall;
