export const DEFAULT = 'DEFAULT';
export const THROW_ERROR = 'THROW_ERROR';
export const THROW_SUCCESS = 'THROW_SUCCESS';
export const IS_LOADING = 'IS_LOADING';
export const RE_RENDERED = 'RE_RENDERED';
export const SET_ARTICLES = 'SET_ARTICLES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ARTICLE_BY_ID = 'SET_ARTICLE_BY_ID';
export const SET_LOADING = 'SET_LOADING';
export const SET_USER = 'SET_USER';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS'; 
export const STORE_NAME_RESET = 'STORE_NAME_RESET';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_CATEGORIES_FETCH_DATA = 'SET_CATEGORIES_FETCH_DATA';
export const SET_ARTICLES_BY_CATEGORY = 'SET_ARTICLES_BY_CATEGORY';