import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import FooterMoreNews from "../FooterMoreNews";
import TwitterFeed from "../TwitterFeed";
import FontAwesome from "../uiStyle/FontAwesome";

import NewsLetter from "../NewsLetter";
import { getAll } from "../../api/Settings";
import { useDispatch } from "react-redux";
import { setOptions } from "../../store/actions";

const FooterArea = ({ className }) => {
  const [email, setEmail] = useState("");
  const [optionsFetch, setOptionsFetch] = useState();

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    setEmail("");
  };

  const getOptions = async () => {
    const res = await getAll();
    const fetchDataToObj = res.reduce(
      (o, key) => ({ ...o, [key.prop]: key.value }),
      {}
    );
    setOptionsFetch(fetchDataToObj);
    dispatch(setOptions(fetchDataToObj));
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <div className={`footer footer_area1 ${className ? className : ""}`}>
        <div className="cta">
          <div className="container">
            <div className="row">
            <div className="col-sm-4">
              <div className="newsletter">
                <NewsLetter text={optionsFetch?.footerNewsletterText} />
              </div>
              <hr className="footer-divide-line"></hr>
              <h3 className="widget-title-footer">ABOUT</h3>
              {optionsFetch?.footerAbout}
              <br />
              <br />
              {optionsFetch?.footerReducePrintwasteText}
              <hr className="footer-divide-line"></hr>
              <div className="social2">
                <ul className="inline">
                  <li>
                  <a href={
                      optionsFetch?.linkTwitter
                    }>
                      <img
                        src={optionsFetch?.footerTwitterIcon}
                        alt="twitter"
                        width={24}
                        height={24}
                      />
                    </a>
                  </li>
                  <li>
                  <a href={
                      optionsFetch?.linkFacebook
                    }>
                      <img
                        src={optionsFetch?.footerFacebookIcon}
                        alt="facebook"
                        width={24}
                        height={24}
                      />
                    </a>
                  </li>
                  <li>
                  <a href={
                      optionsFetch?.linkYoutube
                    }>
                      <img
                        src={optionsFetch?.footerYoutubeIcon}
                        alt="youtube"
                        width={24}
                        height={24}
                      />
                    </a>
                  </li>
                  <li>
                  <a href={
                      optionsFetch?.linkLinkedin
                    }>
                      <img
                        src={optionsFetch?.footerLinkedinIcon}
                        alt="linkedin"
                        width={24}
                        height={24}
                      />
                    </a>
                  </li>
                  <li>
                  <a href={
                      optionsFetch?.linkPinterest
                    }>
                      <img
                        src={optionsFetch?.footerPinterestIcon}
                        alt="pinterest"
                        width={24}
                        height={24}
                      />
                    </a>
                  </li>
                  <li>
                    <a href={
                      optionsFetch?.linkInstagram
                    }>
                      <img
                        src={optionsFetch?.footerInstagramIcon}
                        alt="instagram"
                        width={24}
                        height={24}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <h3 className="widget-title-footer">OFFICIAL – PUBLIC – SPOTS</h3>
              {optionsFetch?.footerOfficialPublicSpots}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot2}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot3}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot4}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot5}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot6}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot7}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot8}
              <br />
              <br />
              {optionsFetch?.footerOfficialPublicSpot9}
            </div>
            <div className="col-sm-2">
              <h3 className="widget-title-footer">KONTAKT</h3>
              <p>{optionsFetch?.footerContact}</p>
              <br />
              <br />
              <p>{optionsFetch?.footerContactAnschrift}</p>
              <br />
              <br />
              <p>{optionsFetch?.footerContactPublisher}</p>
            </div>
            <div className="col-md-6 align-self-center"></div>
          </div>
      </div>
      <FooterCopyright />
    </div>
    </div>
  );
};

export default FooterArea;
