import React, {Fragment} from "react";
import {Link, useHistory} from "react-router-dom";
import Moment from 'moment';

const Preview = ({item}) => {

    const history = useHistory()

    if (!item) {
        return '';
    }

    return (
        <Fragment>
            <div className="single_post post_type3">
                <div className="post_img">
                    <div className="img_wrap">
                        <img src={item.image?.url} alt="thumb" width={360} height={200}/>
                    </div>
                </div>
                <div className="single_post_text">
                    <div className="meta3">
                        <div className="categories">
                            {/* map categories here */}
                            <Link to={`/category/${item?.category.id}`} className="category">
                                {item.category.title}
                            </Link>
                        </div>
                    </div>
                    <Link to={`/article/${item.id}`} className="category">
                        <h2 className="article-title">
                            {item.title.toUpperCase()}
                        </h2>
                    </Link>

                    <div className="author_date-wrapper">
                        <div className="article_author">
                            <Link to={`/article/${item.id}`}><img src="https://secure.gravatar.com/avatar/c76d5eef414fbb830e0c816793d1f7ef?s=32&d=mm&r=g" alt="" style={{
                                marginRight: '5px',
                                marginBottom: '5px'
                            }} width={16} height={16}/>{item.author + ","} </Link>
                        </div>
                        <div style={{width: "5px"}}></div>
                        <div className="date">
                            <Link to={`/article/${item.id}`}>{Moment(item.created).format('DD.MM.YYYY')}</Link>
                        </div>
                    </div>
                    <div className="space-10"/>
                    <p className="post-p">{item.description}</p>
                    <div className="space-20"/>
                    <p className="read-next-button" onClick={() => history.push(`/article/${item.id}`)}>{"Weiterlesen >"}</p>
                    <div className="space-20"/>
                </div>
            </div>
        </Fragment>
    );
};

export default Preview;
