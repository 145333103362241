import { post, get, put, deletion } from "./index"

export const create = (values) => {
    return new Promise((resolve, rej) => {
        post('api/subscription_type/create', values)
        .then(res => resolve(res))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const update = (values) => {
    return new Promise((resolve, rej) => {
        put('api/subscription_type/update', values)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const getById = (id) => {
    return new Promise((resolve, rej) => {
        get(`api/subscription_type/${id}`)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const getAll = () => {
    return new Promise((resolve, rej) => {
        get('api/subscription_type/list')
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const remove = (id) => {
    return new Promise((resolve, rej) => {
        deletion(`api/subscription_type/${id}`)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const userSubscribe = (values) => {
    return new Promise((resolve, rej) => {
        post('api/payment/create-checkout-session', values)
        .then(res => resolve(res))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }