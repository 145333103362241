import React from 'react';

const Heading = ({className, title, firstBlock}) => {
    return (
        <div className={`heading ${className ? className : ''}`}>
            <h4 className={`widget-title ${className ? className : ''}`} style={firstBlock ? {
                color: "#666",
                fontSize: "14px",
                fontWeight: 400,
                marginTop: "50px"
            } : {
                fontWeight: 900,
                textShadow: "0px 0px, 0px 0px, 1px 0px",
                letterSpacing: "3px",
            }}>{title}</h4>
        </div>
    );
};

export default Heading;