import { MenuItem, Select } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import Heading from "../../components/uiStyle/Heading";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input, Label } from "reactstrap";
import { update } from "../../api/User";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { getUserInfo, setUser } from "../../store/actions";
import { LoginPage } from "../LoginPage";
import { useState } from "react";

export const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [gender, setGender] = useState();

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      username: "",
      gender: ""
    },
    onSubmit: (e) => {
      updateUser(e);
    },
  });

  const handleSelectChange = (e, type) => {
    switch (type) {
      case "gender":
        setGender(e.target.value)
        values.gender = e.target.value;
        break;
      default:
        break;
    }
  };

  const updateUser = async (userProp) => {
    await update(userProp);
  };

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    setGender(user?.gender)
    setValues(user);
  }, [user]);

  const { values, handleSubmit, handleChange, setValues } = formik;

  return (
    <div className="container">
      <div className="column mb-3">
        <div className="col-lg-12">
          {user && values ? (
            <>
              <Heading title="Your data" />
              <FormikProvider value={formik}>
              <div className="row">
                <div className="col-sm-6 d-flex flex-column">
                  <Label for="fullName">Full name</Label>
                  <Input id="fullName" value={values.fullName} onChange={handleChange} name="fullName" />
                  <Label for="username" >Username</Label>
                  <Input id="username"  onChange={handleChange} value={values.username} name="username" />
                  <Label for="confirmedEmail">Confirmed Email</Label>
                  <Input
                    id="confirmedEmail"
                    name="confirmedEmail"
                    value={values.confirmedEmail ? "Yes" : "No"}
                    disabled
                  />
                </div>
                <div className="col-sm-6 d-flex flex-column justify-content-start">
                  <Label for="email">Email</Label>
                  <Input id="email" onChange={handleChange} value={values.email} disabled name="email" />
                  <Label id={`select-gender`}>Identity</Label>
                  <Select
                    sx={{ height: "40px" }}
                    labelId={`select-gender`}
                    label={"Identity"}
                    value={gender ? gender : "Not set"}
                    onChange={(e) => handleSelectChange(e, "gender")}
                  >
                    <MenuItem value={"MALE"}>Mr</MenuItem>
                    <MenuItem value={"FEMALE"}>Mrs</MenuItem>
                  </Select>
                </div>
                <button
                  className="sign-in-button ml-3 my-4"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Save
                </button>
              </div>
              </FormikProvider>
            </>
          ) : (
            <LoginPage />
          )}
        </div>
      </div>
    </div>
  );
};
