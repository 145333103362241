function mostViewSort(data) {
  let initial = 0;
  let secondFirst = Math.floor(data.length / 2) - 1;
  let results = [];
  data.forEach((item, i) => {
    if (i % 2) {
      secondFirst += 1;
      results.push({ ...data[secondFirst], id: secondFirst + 1 });
    } else {
      initial += 1;
      results.push({ ...data[initial], id: initial });
    }
  });
  return results;
}

const groupArr = (arr, groupArray) => {
  if(!arr.length) return groupArray;

  groupArray.push(arr.splice(0, 2));
  if (arr.length) {
    return groupArr(arr, groupArray);
  } else {
    return groupArray;
  }
};

const formPagesArr = (start, end) => {
  let arr = []
  for(let i=start; i < end; i++){
      arr.push(i)
  }
  return arr
}



export { mostViewSort, groupArr, formPagesArr };
