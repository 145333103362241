import { post, get, put, deletion } from "./index"

export const create = (values) => {
    return new Promise((resolve, rej) => {
        post('api/article/create', values)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const update = (values) => {
    return new Promise((resolve, rej) => {
        put('api/article/update', values)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const getById = (id) => {
    return new Promise((resolve, rej) => {
        get(`api/article/${id}`)
        .then(res => {
            resolve(res.data)
        })
        .catch(e => {rej(e)}) 
    }) 
  }

  export const getAll = () => {
    return new Promise((resolve, rej) => {
        get('api/article/list')
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const getArticlesListByCategory = (body) => {
    return new Promise((resolve, rej) => {
        post('api/article/free_list', body)
        .then(res => resolve(res))
        .catch(e => {rej(e)}) 
    }) 
  }

  export const remove = (id) => {
    return new Promise((resolve, rej) => {
        deletion(`api/article/${id}`)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }