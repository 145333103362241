import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import { getByProp } from '../../api/Settings';

const LogoArea = ({className, dark}) => {
    const history = useHistory()
    const [logoLink, setLogoLink] = useState('')

    const onClickRedirect = () => {
        history.push('/')
    }

    const getLogoLink = async () => {
        const logoLinkFromFetch = await getByProp('logo')
        setLogoLink(logoLinkFromFetch.value)
    }

    useEffect(() => {
        getLogoLink()
    }, [])

    return (
        <div className={`logo_area ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 align-self-center">
                        <div className="banner1">
                            <div onClick={onClickRedirect}>
                                <img src={logoLink} alt="banner"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoArea;