import React, { Fragment, useEffect, useState } from "react";
import Heading from "../uiStyle/Heading";
import Preview from "../Preview";
import PreviewSmall from "../PreviewSmall";
import { useSelector } from "react-redux";




const CategorySmall = ({ category }) => {

    const articles = useSelector(state => state.article.articleList)
  
    const groupArticles = articles.filter(e => e.category.title === category)

  return (
    <Fragment>
      <Heading title={category} />
      {/* <TrendingNewsSlider /> */}
      <div className="space-30" />
      <div className="row">
        <div className="col-lg-12">
          {groupArticles.length && groupArticles.slice(0, 1).map((item, i) => (
            <Preview key={i + 'preview' + category} item={item}/>
          ))}
        </div> 
         <div className="col-lg-12">
          {groupArticles.length && groupArticles.slice(2, 5).map((item, i) => (
            <PreviewSmall key={i + 'preview' + category} item={item} />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default CategorySmall;
