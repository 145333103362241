import React, { Fragment, useEffect, useState } from "react";
import FontAwesome from "../uiStyle/FontAwesome";
import { getAll } from "../../api/Category";
import { Link, NavLink, useHistory } from "react-router-dom";
import SearchModal from "../SearchModal";
import { removeToken } from "../../api";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SidebarMenu from "../SidebarMenu";


const SignInButton = ({ sideShow, setSideShow, hidden, className, dark }) => {
  const menus1 = [
    {
      id: 2,
      forLoggedIn: true,
      linkText: "Profile",
      link: "/profile",
    },
    {
      id: 3,
      linkText: "Subscription",
      forLoggedIn: true,
      link: "/my-subscriptions",
    },
    {
      id: 4,
      forLoggedIn: true,
      linkText: "Choose Plan",
      link: "/subscribe",
    },
    {
      id: 5,
      linkText: "Logout",
      forLoggedIn: true,
      link: "/",
      action: () => logOut(),
    },
    {
      id: 6,
      linkText: "Login",
      link: "/login",
    }
  ];
  const token = useSelector((state) => state.user.token);
  const categories = useSelector((state) => state.options.categories)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menus, setMenus] = useState(menus1)

  const history = useHistory();


  useEffect(() => {
    const tempMenus = [{
      id: 1,
      linkText: "Home",
      link: "/",
    }]
    categories.forEach((cat) => {
      tempMenus.push({
        id: cat.id,
        linkText: cat.title,
        isCategory: true,
        link: `/category/${cat.id}`,
      })
    })
    let filtered = menus1.filter(e => !e.forLoggedIn)
    if(token) {
      filtered = menus1.filter(e => e.forLoggedIn)
    } 
    setMenus([...filtered, ...tempMenus])
  }, [categories, token])

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const logOut = () => {
    removeToken();
    window.location.reload();
  };

  return (
    <Fragment>
        <div className={`${hidden ? "sign-in-hidden" : ""}`}>
                {token ? (
                  <div className={`pointer top-dropdown`}>
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                      direction={"down"}
                    >
                      <DropdownToggle style={{backgroundColor: "#fff", border: '0'}} caret>
                        <FontAwesome name="user fa-lg" style={{color: "#000", width: "16px"}} />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>Menu</DropdownItem>
                        <DropdownItem onClick={() => history.push("/profile")}>
                          <FontAwesome style={{marginRight: "5px"}} name="user-o fa-lg" />
                          Profile
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => history.push("/my-subscriptions")}
                        >
                          <FontAwesome style={{marginRight: "5px"}} name="credit-card" />
                          Subscriptions
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => history.push("/subscribe")}
                        >
                          <FontAwesome style={{marginRight: "10px"}} name="usd" />
                          Choose plan
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={logOut}>
                        <FontAwesome style={{marginRight: "5px"}} name="sign-out" />
                          Logout
                          </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                ) : (
                  <Link to="/login" className="top_login" style={{fontSize: "14px", color: "#000", marginTop: "5px", marginLeft: "5px"}}>
                    <FontAwesome name="sign-in fa-lg" />
                  </Link>
                )}
                <SidebarMenu
                  sideShow={sideShow}
                  setSideShow={setSideShow}
                  menus={menus}
                />
            </div>
    </Fragment>
  );
};

export default SignInButton;
