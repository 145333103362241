import React, {Fragment} from "react";
import {Link, useHistory} from "react-router-dom";
import Moment from 'moment';
import { getArticleById } from "../../store/actions";
import { useDispatch } from "react-redux";

const PreviewRelated = ({item}) => {

    const history = useHistory()
    const dispatch = useDispatch()

    if (!item) {
        return '';
    }

    return (
        <Fragment>
            <div className="single_post post_type3" onClick={() => (dispatch(getArticleById(item.id)), window.scrollTo({top: 0, behavior: 'smooth'})) } style={{marginTop: "15px"}}>
            <Link to={`/article/${item.id}`} className="category">
                        <h2 className="article-title-small">
                            {item.title.toUpperCase()}
                        </h2>
                    </Link>

                    <div className="author_date-wrapper" style={{marginBottom: "10px"}}>
                        <div className="article_author">
                            <Link to={`/article/${item.id}`}><img src="https://secure.gravatar.com/avatar/c76d5eef414fbb830e0c816793d1f7ef?s=32&d=mm&r=g" alt="" style={{
                                marginRight: '5px',
                                marginBottom: '5px'
                            }} width={16} height={16}/>{item.author + ","} </Link>
                        </div>
                        <div style={{width: "5px"}}></div>
                        <div className="date">
                            <Link to={`/article/${item.id}`}>{Moment(item.created).format('DD.MM.YYYY')}</Link>
                        </div>
                    </div>
                <div className="post_img">
                    <div className="img_wrap">
                        <img src={item.image?.url} alt="thumb" width={230} height={130}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PreviewRelated;
