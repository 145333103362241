import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PrivateRoute from '../_PrivateRoute';
import HomePage from "../../containers/HomePage";
// import HomePageTwo from "../../containers/HomePageTwo";
import BusinessPage from "../../containers/BusinessPage";
import EntertainmentPage from "../../containers/EntertainmentPage";
import FeaturePage from "../../containers/FeaturePage";
import SportsPage from "../../containers/SportsPage";
import TrendingPage from "../../containers/TrendingPage";
import AboutUsPage from "../../containers/AboutUsPage";
import ArchivePage from "../../containers/ArchivePage";
import ContactUsPage from "../../containers/ContactUsPage";
import NotFoundPage from "../../containers/NotFoundPage";
import PostOnePage from "../../containers/PostOnePage";
import PostTwoPage from "../../containers/PostTwoPage";
import PostThreePage from "../../containers/PostThreePage";
import VideoPostOnePage from "../../containers/VideoPostOnePage";
import VideoPostTwoPage from "../../containers/VideoPostTwoPage";
import VideoPostThreePage from "../../containers/VideoPostThreePage";
import AudioPostOnePage from "../../containers/AudioPostOnePage";
import AudioPostTwoPage from "../../containers/AudioPostTwoPage";
import AudioPostThreePage from "../../containers/AudioPostThreePage";
import PostOneLeftSidebarPage from "../../containers/PostOneLeftSidebarPage";
import BusinessTwoPage from "../../containers/BusinessTwoPage";
import EntertainmentTwoPage from "../../containers/EntertainmentTwoPage";
import FeatureTwoPage from "../../containers/FeatureTwoPage";
import SportsTwoPage from "../../containers/SportsTwoPage";
import TrendingTwoPage from "../../containers/TrendingTwoPage";
import AboutUsPageTwo from "../../containers/AboutUsTwoPage";
import ArchiveTwoPage from "../../containers/ArchiveTwoPage";
import PostOneHTwoPage from "../../containers/PostOneHTwoPage";
import PostTwoHTwoPage from "../../containers/PostTwoHTwoPage";
import PostThreeHTwoPage from "../../containers/PostThreeHTwoPage";
import VideoPostOneHTwoPage from "../../containers/VideoPostOneHTwoPage";
import VideoPostTwoHTwoPage from "../../containers/VideoPostTwoHTwoPage";
import VideoPostThreeHTwoPage from "../../containers/VideoPostThreeHTwoPage";
import AudioPostOneHTwoPage from "../../containers/AudioPostOneHTwoPage";
import AudioPostTwoHTwoPage from "../../containers/AudioPostTwoHTwoPage";
import AudioPostThreeHTwoPage from "../../containers/AudioPostThreeHTwoPage";
import PostOneHTwoLeftSidebarPage from "../../containers/PostOneHTwoLeftSidebarPage";
// import HomePageThree from "../../containers/HomePageThree";
import BusinessThreePage from "../../containers/BusinessThreePage";
import EntertainmentThreePage from "../../containers/EntertainmentThreePage";
import FeatureThreePage from "../../containers/FeatureThreePage";
import SportsThreePage from "../../containers/SportsThreePage";
import TrendingThreePage from "../../containers/TrendingThreePage";
import AboutUsThreePage from "../../containers/AboutUsThreePage";
import ArchiveThreePage from "../../containers/ArchiveThreePage";
import ContactUsThreePage from "../../containers/ContactUsThreePage";
import NotFoundThreePage from "../../containers/NotFoundThreePage";
import PostOneHThreePage from "../../containers/PostOneHThreePage";
import PostTwoHThreePage from "../../containers/PostTwoHThreePage";
import PostThreeHThreePage from "../../containers/PostThreeHThreePage";
import VideoPostOneHThreePage from "../../containers/VideoPostOneHThreePage";
import VideoPostTwoHThreePage from "../../containers/VideoPostTwoHThreePage";
import VideoPostThreeHThreePage from "../../containers/VideoPostThreeHThreePage";
import AudioPostOneHThreePage from "../../containers/AudioPostOneHThreePage";
import AudioPostTwoHThreePage from "../../containers/AudioPostTwoHThreePage";
import AudioPostThreeHThreePage from "../../containers/AudioPostThreeHThreePage";
import PostOneHThreeLeftSidebarPage from "../../containers/PostOneHThreeLeftSidebarPage";
import BusinessDarkPage from "../../containers/BusinessDarkPage";
import EntertainmentDarkPage from "../../containers/EntertainmentDarkPage";
import FeatureDarkPage from "../../containers/FeatureDarkPage";
import SportsDarkPage from "../../containers/SportsDarkPage";
import TrendingDarkPage from "../../containers/TrendingDarkPage";
import AboutUsDarkPage from "../../containers/AboutUsDarkPage";
import ArchiveDarkPage from "../../containers/ArchiveDarkPage";
import ContactUsDarkPage from "../../containers/ContactUsDarkPage";
import NotFoundDarkPage from "../../containers/NotFoundDarkPage";
import PostOneDarkPage from "../../containers/PostOneDarkPage";
import PostTwoDarkPage from "../../containers/PostTwoDarkPage";
import PostThreeDarkPage from "../../containers/PostThreeDarkPage";
import VideoPostOneDarkPage from "../../containers/VideoPostOneDarkPage";
import VideoPostTwoDarkPage from "../../containers/VideoPostTwoDarkPage";
import VideoPostThreeDarkPage from "../../containers/VideoPostThreeDarkPage";
import AudioPostOneDarkPage from "../../containers/AudioPostOneDarkPage";
import AudioPostTwoDarkPage from "../../containers/AudioPostTwoDarkPage";
import AudioPostThreeDarkPage from "../../containers/AudioPostThreeDarkPage";
import PostOneLeftSidebarDarkPage from "../../containers/PostOneLeftSidebarDarkPage";
import {LoginPage} from '../../containers/LoginPage';
import {RegisterPage} from '../../containers/RegisterPage';
import {UserProfile} from '../../containers/UserProfile';
import {SubscriptionProfileView} from '../../containers/UserProfile/SubscriptionProfileView';
import CategoryPage from '../../components/CategoryPage';
import { SubscriptionPage } from '../../components/SubscriptionPage';
import { ForgotPage } from '../../containers/ForgotPage';
import { NewPassPage } from '../../containers/NewPasswordPage';
import { ConfirmEmailPage } from '../../components/ConfirmEmailPage';
import { ConfirmEmailSuccess } from '../../components/ConfirmEmailSuccess';
// import PublicRoute from '../_PublicRoute';

const Routes = () => {
    return (
        <Switch>
            {/*home one routes*/}
            <PrivateRoute
                exact
                path="/login"
                parentClass="theme-1"
                component={LoginPage}/>
            <PrivateRoute
                exact
                path="/register"
                parentClass="theme-1"
                component={RegisterPage}/>
            <PrivateRoute
                exact
                path="/confirm"
                parentClass="theme-1"
                component={ConfirmEmailPage}/>
            <PrivateRoute
                exact
                path="/confirm-success/:token"
                parentClass="theme-1"
                component={ConfirmEmailSuccess}/>
            <PrivateRoute
                exact
                path="/forgot"
                parentClass="theme-1"
                component={ForgotPage}/>
            <PrivateRoute
                exact
                path="/new-password/:token"
                parentClass="theme-1"
                component={NewPassPage}/>
            <PrivateRoute
                path="/category/:categoryId"
                parentClass="theme-1"
                component={CategoryPage}/>
            <PrivateRoute
                exact
                path="/register"
                parentClass="theme-1"
                component={RegisterPage}/>
            <PrivateRoute
                exact
                path="/subscribe"
                parentClass="theme-1"
                component={SubscriptionPage}/>
                <PrivateRoute
                exact
                path="/profile"
                parentClass="theme-1"
                component={UserProfile}/>
                <PrivateRoute
                exact
                path="/my-subscriptions"
                parentClass="theme-1"
                component={SubscriptionProfileView}/>
            <PrivateRoute
                exact
                path="/"
                parentClass="theme-1"
                component={HomePage}/>
            <PrivateRoute
                exact
                path="/"
                parentClass="theme-1"
                component={HomePage}/>
            <PrivateRoute
                exact
                path="/business"
                parentClass="theme-1"
                component={BusinessPage}/>
            <PrivateRoute
                exact
                path="/entertainment"
                parentClass="theme-1"
                component={EntertainmentPage}/>
            <PrivateRoute
                exact
                path="/features"
                parentClass="theme-1"
                component={FeaturePage}/>
            <PrivateRoute
                exact
                path="/trending"
                parentClass="theme-1"
                component={TrendingPage}/>
            <PrivateRoute
                exact
                path="/sports"
                parentClass="theme-1"
                component={SportsPage}/>
            <PrivateRoute
                exact
                path="/about"
                parentClass="theme-1"
                component={AboutUsPage}/>
            <PrivateRoute
                exact
                path="/archive"
                parentClass="theme-1"
                component={ArchivePage}/>
            <PrivateRoute
                exact
                path="/contact"
                parentClass="theme-1"
                component={ContactUsPage}/>
            <PrivateRoute
                exact
                path="/404"
                parentClass="theme-1"
                component={NotFoundPage}/>
            <PrivateRoute
                exact
                path="/article/:id"
                parentClass="theme-1"
                component={PostOnePage}/>
            <PrivateRoute
                exact
                path="/post2"
                parentClass="theme-1"
                component={PostTwoPage}/>
            <PrivateRoute
                exact
                path="/post3"
                parentClass="theme-1"
                component={PostThreePage}/>
            <PrivateRoute
                exact
                path="/video_post1"
                parentClass="theme-1"
                component={VideoPostOnePage}/>
            <PrivateRoute
                exact
                path="/video_post2"
                parentClass="theme-1"
                component={VideoPostTwoPage}/>
            <PrivateRoute
                exact
                path="/video_post3"
                parentClass="theme-1"
                component={VideoPostThreePage}/>
            <PrivateRoute
                exact
                path="/audio_post1"
                parentClass="theme-1"
                component={AudioPostOnePage}/>
            <PrivateRoute
                exact
                path="/audio_post2"
                parentClass="theme-1"
                component={AudioPostTwoPage}/>
            <PrivateRoute
                exact
                path="/audio_post3"
                parentClass="theme-1"
                component={AudioPostThreePage}/>
            <PrivateRoute
                exact
                path="/left_post2"
                parentClass="theme-1"
                component={PostOneLeftSidebarPage}/>

            {/*home two routes*/}
            {/* <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two"
                component={HomePageTwo}/> */}
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/business"
                component={BusinessTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/entertainment"
                component={EntertainmentTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/features"
                component={FeatureTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/sports"
                component={SportsTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/trending"
                component={TrendingTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/about"
                component={AboutUsPageTwo}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/archive"
                component={ArchiveTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/home-two/contact"
                component={ContactUsPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/post1"
                component={PostOneHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/post2"
                component={PostTwoHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/post3"
                component={PostThreeHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/video_post1"
                component={VideoPostOneHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/video_post2"
                component={VideoPostTwoHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/video_post3"
                component={VideoPostThreeHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/audio_post1"
                component={AudioPostOneHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/audio_post2"
                component={AudioPostTwoHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/audio_post3"
                component={AudioPostThreeHTwoPage}/>
            <PrivateRoute
                exact
                home_style={2}
                parentClass="theme-3"
                path="/home-two/left_post2"
                component={PostOneHTwoLeftSidebarPage}/>

            {/*home page three*/}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three"
                component={HomePageThree}/> */}
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/business"
                component={BusinessThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/entertainment"
                component={EntertainmentThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/features"
                component={FeatureThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/sports"
                component={SportsThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/trending"
                component={TrendingThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/about"
                component={AboutUsThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/archive"
                component={ArchiveThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/contact"
                component={ContactUsThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/404"
                component={NotFoundThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/post1"
                component={PostOneHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/post2"
                component={PostTwoHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 theme3_bg"
                path="/home-three/post3"
                component={PostThreeHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post1"
                component={VideoPostOneHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post2"
                component={VideoPostTwoHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post3"
                component={VideoPostThreeHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post1"
                component={AudioPostOneHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post2"
                component={AudioPostTwoHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post3"
                component={AudioPostThreeHThreePage}/>
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/left_post2"
                component={PostOneHThreeLeftSidebarPage}/>

            {/*home dark version*/}
            {/* <PrivateRoute
                exact
                home_style={4}
                path="/dark"
                parentClass="dark-theme primay_bg"
                component={HomeDarkPage}/> */}
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/business"
                parentClass="dark-theme primay_bg"
                component={BusinessDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/entertainment"
                parentClass="dark-theme primay_bg"
                component={EntertainmentDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/features"
                parentClass="dark-theme primay_bg"
                component={FeatureDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/sports"
                parentClass="dark-theme primay_bg"
                component={SportsDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/trending"
                parentClass="dark-theme primay_bg"
                component={TrendingDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/about"
                parentClass="dark-theme primay_bg"
                component={AboutUsDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/archive"
                parentClass="dark-theme primay_bg"
                component={ArchiveDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/contact"
                parentClass="dark-theme primay_bg"
                component={ContactUsDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/404"
                parentClass="dark-theme primay_bg"
                component={NotFoundDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/post1"
                parentClass="dark-theme primay_bg"
                component={PostOneDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/post2"
                parentClass="dark-theme primay_bg"
                component={PostTwoDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/post3"
                parentClass="dark-theme primay_bg"
                component={PostThreeDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/video_post1"
                parentClass="dark-theme primay_bg"
                component={VideoPostOneDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/video_post2"
                parentClass="dark-theme primay_bg"
                component={VideoPostTwoDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/video_post3"
                parentClass="dark-theme primay_bg"
                component={VideoPostThreeDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/audio_post1"
                parentClass="dark-theme primay_bg"
                component={AudioPostOneDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/audio_post2"
                parentClass="dark-theme primay_bg"
                component={AudioPostTwoDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/audio_post3"
                parentClass="dark-theme primay_bg"
                component={AudioPostThreeDarkPage}/>
            <PrivateRoute
                exact
                home_style={4}
                path="/dark/left_post2"
                parentClass="dark-theme primay_bg"
                component={PostOneLeftSidebarDarkPage}/>

            <Route exact component={NotFoundPage}/>
        </Switch>
    );
};
export default Routes