import React, { Fragment, useEffect, useState } from "react";
import FontAwesome from "../uiStyle/FontAwesome";
import { getAll } from "../../api/Category";
import { Link, NavLink, useHistory } from "react-router-dom";
import SearchModal from "../SearchModal";
import { removeToken } from "../../api";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SidebarMenu from "../SidebarMenu";
import { setCategoriesFetchData } from "../../store/actions";
import SignInButton from "../SignInButton/SignInButton";

const MainMenu = ({ className, dark }) => {
  const [searchShow, setSearchShow] = useState(false);
  const [sideShow, setSideShow] = useState(false);

  const options = useSelector((state) => state.options.options);
  const categories = useSelector((state) => state.options.categories);

  const dispatch = useDispatch();

  const sortCategories = () => {
    let sortedCategories = [];
    if (Object.keys(options).length > 0 && categories.length > 0) {
      switch (options.categorySorting) {
        case "desc":
          sortedCategories = categories
            .sort((a, b) => a.title.localeCompare(b.title))
            .reverse();
          dispatch(setCategoriesFetchData(sortedCategories));
          break;
        case "asc":
          sortedCategories = categories.sort((a, b) =>
            a.title.localeCompare(b.title)
          );
          dispatch(setCategoriesFetchData(sortedCategories));
          break;
        default:
          return categories;
      }
    }
  };


  const getCategories = async () => {
    const res = await getAll();
    dispatch(setCategoriesFetchData(res));
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    sortCategories();
  }, [options, categories]);

  return (
    <Fragment>
      <div className={`main-menu ${className ? className : ""}`} id="header">
        <Link to="#top" className="up_btn up_btn1">
          <FontAwesome name="chevron-double-up" />
        </Link>
        <div className="main-nav is-ts-sticky">
          <div className="com-sm-12">
          <div className="menu_right nopadding">
              <div className="topbar" id="top">
                { options &&
                <div className="top_date_social text-right">
                  <div className={`social1`}>
                    <ul className="inline">
                      <li>
                        <a href={options?.linkTwitter}>
                          <img
                            src={options?.headerTwitterIcon}
                            alt="twitter"
                            width={18}
                            height={18}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={options?.linkLinkedin}>
                          <img
                            src={options?.headerLinkedinIcon}
                            alt="linkedin"
                            width={18}
                            height={18}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={options?.linkPinterest}>
                          <img
                            src={options?.headerPinterestIcon}
                            alt="pinterest"
                            width={18}
                            height={18}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={options?.linkFacebook}>
                          <img
                            src={options?.headerFacebookIcon}
                            alt="facebook"
                            width={18}
                            height={18}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={options?.linkYoutube}>
                          <img
                            src={options?.headerYoutubeIcon}
                            alt="youtube"
                            width={18}
                            height={18}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={options?.linkInstagram}>
                          <img
                            src={options?.headerInstagramIcon}
                            alt="instagram"
                            width={18}
                            height={18}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <SignInButton sideShow={sideShow} setSideShow={setSideShow}/>
                </div>
                }
              </div>
            </div>
            <nav className="col-sm-12 noppading align-self-center" id="navbar-float">
            {/* <SignInButton sideShow={sideShow} setSideShow={setSideShow} hidden/> */}
              <div className="navbar site-nav-inner">
                <button
                  className="navbar-toggler"
                  onClick={() => setSideShow(true)}
                >
                  <FontAwesome name="bars" />
                </button>
                <div
                  id="navbarSupportedContent"
                  className="collapse navbar-collapse navbar-responsive-collapse"
                >
                  <ul className="nav navbar-nav">
                    {categories.length > 0
                      ? categories.map((item, i) => (
                          <li
                            key={i}
                            className={`
                                                ${item.child ? "dropdown" : ""}
                                                nav-item`}
                          >
                            <NavLink
                              exact
                              to={`/category/${item.id}`}
                              data-toggle="dropdown"
                            >
                              {item?.title?.toUpperCase()}
                            </NavLink>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
            </nav>
            
          </div>
        </div>
      </div>
      {searchShow ? (
        <SearchModal setSearchShow={setSearchShow} searchShow={searchShow} />
      ) : null}
    </Fragment>
  );
};

export default MainMenu;
