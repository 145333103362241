import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Input } from "reactstrap";
import { restorePass } from "../../api/Auth";

export const NewPassPage = () => {
    const {token} = useParams()
    const history = useHistory()

    const newPass = async (values) => {
        await restorePass(values);
    };


  const formik = useFormik({
    initialValues: {
      password: "",
      token: token
    },
    onSubmit: () => {
      newPass(values);
      history.push("/login")
    },
  });

  const { values, handleSubmit, handleChange } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="container">
          <div className="column mb-3">
            <div className="col-lg-8">
              <label>Password</label>
              <Input id="password" type="password" value={values.password} onChange={handleChange} />
            </div>
            <div className="col-lg-12">
              <button className="sign-in-button" type="submit">Change password</button>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
