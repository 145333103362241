import React from "react";
import { Link } from "react-router-dom";

const quick_links = [
  {
    name: "About",
    link: "/",
  },
  {
    name: "Advertise",
    link: "/",
  },
  {
    name: "Privacy & Policy",
    link: "/",
  },
  {
    name: "Contact Us",
    link: "/",
  },
];

const FooterCopyright = () => {
  return (
    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <p>
              &copy; Copyright © 2014. All rights reserved. | Webagentur BEKTEN
              GmbH
            </p>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="copyright_menus text-right">
              <div className="language" />
              <p
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
              >
                ↑ Zurück nach oben
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCopyright;
