import {get, post} from "./index"

export const login = (values) => {
    return new Promise((resolve, rej) => {
        post('api/auth/signin', values)
            .then(res => resolve(res))
            .catch(e => {
                rej(e.response.data.message)
            })
    })
}

export const register = (values) => {
    return new Promise((resolve, rej) => {
        post('api/auth/register?url=https://zyu.ch/confirm-success', values)
        .then(res => resolve(res))
        .catch(e => {rej(e)}) 
    }) 
  }

export const forgot = (values) => {
    return new Promise((resolve, rej) => {
        post(`api/auth/forgot-password?email=${values.email}&url=${values.url}`)
            .then(res => console.log(res))
            .catch(e => {
                rej(e.response.data.message)
            })
    })
}

export const restorePass = (values) => {
    return new Promise((resolve, rej) => {
        post(`api/auth/change-password`, values)
            .then(res => console.log(res))
            .catch(e => {
                rej(e.response.data.message)
            })
    })
}

export const confirmEmail = (values) => {
    return new Promise((resolve, rej) => {
        get(`api/auth/confirm?token=${values.token}`)
            .then(res => console.log(res))
            .catch(e => {
                rej(e.response.data.message)
            })
    })
}