import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Input, Label } from "reactstrap";
import { register } from "../../api/Auth";
import { useDispatch } from "react-redux";
import { setError, setTokenToStore } from "../../store/actions";
import { getToken, setToken } from "../../api";
import Heading from "../../components/uiStyle/Heading";

export const RegisterPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    fullName: Yup.string().required("Full name is required"),
    username: Yup.string().required("Username is required"),
  });

  const Register = async (values) => {
    const data = await register(values)
      .then((res) => history.push("/confirm"))
      .catch((e) => dispatch(setError(e)));
    dispatch(setTokenToStore(data.accessToken));
    setToken(data.accessToken);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      fullName: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      await Register(values);
    },
  });

  const { values, touched, errors, handleSubmit, handleChange } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Heading title="Register" />
        <div style={{ marginBottom: "5%", marginTop: "5%" }}>
          <div className="column">
            <div className="col-lg-6">
              <Label for="email">Email</Label>
              <Input
                id="email"
                style={{ width: "100%", marginBottom: "2%" }}
                type="email"
                value={values.email}
                onChange={handleChange}
              />
              {touched.email && errors.email ? (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errors.email}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6">
              <Label for="fullName">Full name</Label>
              <Input
                id="fullName"
                label="Full name"
                style={{ width: "100%", marginBottom: "2%" }}
                type="text"
                value={values.fullName}
                onChange={handleChange}
              />
              {touched.fullName && errors.fullName ? (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errors.fullName}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6">
              <Label for="username">Username</Label>
              <Input
                id="username"
                label="Username"
                style={{ width: "100%", marginBottom: "2%" }}
                type="text"
                value={values.username}
                onChange={handleChange}
              />
              {touched.username && errors.username ? (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errors.username}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6">
              <Label for="password">Password</Label>
              <Input
                id="password"
                label="Password"
                style={{ width: "100%", marginBottom: "2%" }}
                type="password"
                value={values.password}
                onChange={handleChange}
              />
              {touched.password && errors.password ? (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errors.password}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6">
              <button
                className="sign-in-button my-1"
                style={{ width: "100%" }}
                type="submit"
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
