import { get } from "../api"

export const getById = (id) => {
    return new Promise((resolve, rej) => {
        get(`api/settings/${id}`)
        .then(res => {
            resolve(res.data)
        })
        .catch(e => {rej(e)}) 
    }) 
  }

  export const getByProp = (prop) => {
    return new Promise((resolve, rej) => {
        get(`api/settings/prop/${prop}`)
        .then(res => {
            resolve(res.data)
        })
        .catch(e => {rej(e)}) 
    }) 
  }

  export const getAll = () => {
    return new Promise((resolve, rej) => {
        get('api/settings/list')
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }