import React from "react";
import { useHistory } from "react-router-dom";

const NewsLetter = ({ className, input_white, titleClass, text }) => {
  const history = useHistory();

  return (
    <div className={`widget news_letter ${className ? className : ""}`}>
      <h2 className={`widget-title-footer ${titleClass}`}>NEWSLETTER</h2>
      <p>
        {text}
      </p>
      <div className="space-20" />
      <div className="signup_form">
        <form>
          <input
            className={`signup ${input_white ? "white_bg" : ""}`}
            type="email"
            placeholder="Deine E-Mail ..."
          />
          <button type="button" className="cbtn">
            ANHMELDEN
          </button>
        </form>
        <div className="space-10" />
      </div>
    </div>
  );
};

export default NewsLetter;
