import React, { Fragment, useEffect } from "react";
import Heading from "../uiStyle/Heading";
import Preview from "../Preview";
import PreviewSmall from "../PreviewSmall";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Redirect } from "react-router-dom";
import {
  getArticles,
  getArticlesByCategory,
} from "../../store/actions";
import { useLocationWithQuery } from "react-router-query-hooks";
import ReactPaginate from "react-paginate";
import CategoriesWidget from "../CategoriesWidget";

const CategoryPage = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { categoryId } = useParams();
  const location = useLocationWithQuery();
  const { query } = location;
  const articles = useSelector((state) => state.article.articlesForCategory);
  const categories = useSelector((state) => state.options.categories);
  const groupArticles = articles.filter((e) => e.category.id == categoryId);
  const offset = query?.page * 1 || 0;

  const onPageChange = (pageNumber) => {
    history.push(`/category/${categoryId}?page=${pageNumber.selected + 1}`);
  };

  useEffect(() => {
    dispatch(getArticles())
    dispatch(getArticlesByCategory(categoryId));

    return () => {
      // dispatch(resetStore());
    };
  }, [categoryId]);

  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-sm-7 col-md-8" id="big-container">
            <Heading firstBlock className={"noppading"} title={"BEITRÄGE IN DER KATEGORIE"}/>
            <Heading title={groupArticles[offset]?.category.title} />
            <hr className="divide-block-line"></hr>
            <div className="space-30" />
            <div className="row">
              <div className="col-lg-6">
                <Preview key={"preview_1"} item={groupArticles[offset]} />
                {groupArticles.length
                  ? groupArticles
                      .slice(offset + 2, offset + 4)
                      .map((item, i) => (
                        <Preview
                          key={i + "preview_small" + item?.category.title}
                          item={item}
                        />
                      ))
                  : ""}
              </div>
              <div className="col-lg-6">
                <Preview key={"preview_2"} item={groupArticles[offset + 1]} />
                {groupArticles.length
                  ? groupArticles
                      .slice(offset + 5, offset + 7)
                      .map((item, i) => (
                        <Preview
                          key={i + "preview_small" + item?.category.title}
                          item={item}
                        />
                      ))
                  : ""}
              </div>
            </div>
            <hr className="divide-block-line"></hr>
            <ReactPaginate
              breakLabel="..."
              nextLabel="»"
              previousLabel="«"
              onPageChange={onPageChange}
              pageRangeDisplayed={5}
              pageCount={groupArticles.length / 5}
              renderOnZeroPageCount={null}
              pageClassName="page-solo-item"
              pageLinkClassName="page-custom-link"
              previousClassName="page-solo-item"
              previousLinkClassName="page-custom-link"
              nextClassName="page-solo-item"
              nextLinkClassName="page-custom-link"
              breakClassName="page-solo-item"
              breakLinkClassName="page-custom-link"
              containerClassName="pagination-wrap"
              activeClassName="active"
            />
          </div>
          <div className="col-sm-5 col-md-4">
            <CategoriesWidget categories={categories} articlePage/>
            <hr className="divide-block-line"></hr>
              <div className="block-title">
                <h1>LETZTE BEITRÄGE</h1>
                  {articles &&
                    articles?.sort((a,b) => new Date(b.created) - new Date(a.created)).slice(0, 1).map((e) => (
                        <Preview item={e} />
                    ))}
                    {articles &&
                    articles?.sort((a,b) => new Date(b.created) - new Date(a.created)).slice(1, 5).map((e) => (
                        <PreviewSmall item={e} />
                    ))}
              </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CategoryPage;
