import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Alert } from "reactstrap"

export const MessageContext = React.createContext("")

const classes = {
  alert: {
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: "999",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(255, 231, 236) !important",
  },
  success: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    zIndex: "999",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(206, 255, 189) !important",
  }
}

export const MessageNotificationHOC = ({ children }) => {
  const error = useSelector(state => state.meta.error)
  console.log(error)
  return (
    <>
      {children}
      {error ? (
         <Alert style={classes.alert} color="danger">
          {" "}
          {error?.message}
        </Alert>
      ) : ''}
    </>
  )
}
