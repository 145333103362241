import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";

export const LoadingHOC = ({ children }) => {
  const styles = {
    loading: {
      position: "fixed",
      top: "50%",
      left: "50%"
    },
  };
  const { isLoading } = useSelector((state) => state.meta);

  return (
    <>
      {children}
      {isLoading && <Spinner style={styles.loading} color="primary">Loading...</Spinner>}
    </>
  );
};
