import {
  IS_LOADING,
  RE_RENDERED,
  THROW_ERROR,
  THROW_SUCCESS,
  SET_OPTIONS,
  STORE_NAME_RESET,
  SET_CATEGORIES_FETCH_DATA,
} from "../constants";
import { toast } from "react-toastify";

const init = {
  isLoading: false,
  error: "",
  success: "",
  options: {},
  categories: [],
  reRendered: false,
};

const optionsReducer = (state = init, action) => {
  switch (action.type) {
    case THROW_ERROR: {
      toast.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    }
    case THROW_SUCCESS: {
      toast.success(action.payload);
      return {
        ...state,
        success: action.payload,
      };
    }
    case STORE_NAME_RESET: {
      return { ...init };
    }
    case SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
      case SET_CATEGORIES_FETCH_DATA:
        return {
          ...state,
          categories: action.payload,
        };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case RE_RENDERED:
      return {
        ...state,
        reRendered: action.payload,
      };
    default:
      return state;
  }
};

export default optionsReducer;
