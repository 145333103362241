import React, { Fragment } from "react";
import Heading from "../uiStyle/Heading";
import Preview from "../Preview";
import PreviewSmall from "../PreviewSmall";
import { useSelector } from "react-redux";

const News = () => {
  const articles = useSelector((state) => state.article.articleList);

  return (
    <Fragment>
      <Heading title={"NEWS"} firstBlock />
      {/* <TrendingNewsSlider /> */}
      <div className="space-20" />
      <div className="row">
        <div className="col-lg-6">
          <Preview item={articles[0]} />
          {articles.length &&
            articles
              .slice(2, 4)
              .map((item, i) => (
                <PreviewSmall key={i + "preview"} item={item} />
              ))}
          <div className="space-20 thin-line-dotted" />
        </div>
        <div className="col-lg-6">
          <Preview item={articles[1]} />
          {articles.length &&
            articles
              .slice(5, 7)
              .map((item, i) => (
                <PreviewSmall key={i + "preview"} item={item} />
              ))}
                      <div className="space-20 thin-line-dotted"/>
        </div>
      </div>
    </Fragment>
  );
};

export default News;
