import React from "react";

export const ConfirmEmailPage = () => {
  return (
    <div className="container" style={{ marginBottom: "7%" }}>
      <h1>Check your mailbox</h1>
      <p style={{ marginBottom: "3%" }}>
        To confirm your email click on the link in mail
      </p>
      <div >
        <img style={{marginLeft: "12%"}} src="/confirm-message.png" alt="mail" />
      </div>
    </div>
  );
};
