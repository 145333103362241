import React, { Fragment } from "react";
import Heading from "../uiStyle/Heading";
import Preview from "../Preview";
import PreviewSmall from "../PreviewSmall";
import { useSelector } from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { useLocationWithQuery } from "react-router-query-hooks";

const Category = ({ category }) => {
  const articles = useSelector((state) => state.article.articleList);
  const groupArticles =
    articles.filter((e) => e.category.title === category) || [];
  let history = useHistory();
  const location = useLocationWithQuery();
  const { query } = location;
  const offset = query?.page || 0;

  const onPageChange = (pageNumber) => {
    history.push(
      `/category/${groupArticles[0].category.id}?page=${pageNumber.selected + 1}`
    );
  };

  return (
    <Fragment>
      <Heading title={category} />
      {/* <TrendingNewsSlider /> */}
      <div className="space-30" />
      <div className="row">
        <div className="col-sm-6">
          <Preview key={"preview" + category} item={groupArticles[offset]} />
          {groupArticles.length &&
            groupArticles
              .slice(offset + 2, offset + 4)
              .map((item, i) => (
                <PreviewSmall
                  key={i + "preview_small" + category}
                  item={item}
                />
              ))}
              <div className="space-20 thin-line-dotted"/>
        </div>
        <div className="col-sm-6">
          <Preview
            key={"preview" + category}
            item={groupArticles[offset + 1]}
          />
          {groupArticles.length &&
            groupArticles
              .slice(offset + 5, offset + 7)
              .map((item, i) => (
                <PreviewSmall
                  key={i + "preview_small" + category}
                  item={item}
                />
              ))}
              <div className="space-20 thin-line-dotted"/>
        </div>
      </div>
      <div className="space-20 thin-line"></div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="»"
        previousLabel="«"
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        pageCount={groupArticles.length/5}
        renderOnZeroPageCount={null}
        pageClassName="page-solo-item"
        pageLinkClassName="page-custom-link"
        previousClassName="page-solo-item"
        previousLinkClassName="page-custom-link"
        nextClassName="page-solo-item"
        nextLinkClassName="page-custom-link"
        breakClassName="page-solo-item"
        breakLinkClassName="page-custom-link"
        containerClassName="pagination-wrap"
        activeClassName="active"
      />
    </Fragment>
  );
};

export default Category;
