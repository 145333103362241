import {combineReducers} from 'redux'
import articleReducer from './articleReducer';
import metaReducer from "./metaReducer";
import optionsReducer from './optionsReducer';
import subscriptionReducer from "./subscriptionReducer";
import userReducer from './userReducer';

const rootReducer = combineReducers({
    meta: metaReducer,
    article: articleReducer,
    subscription: subscriptionReducer,
    user: userReducer,
    options: optionsReducer
});
export default rootReducer