import React, {Fragment, useEffect} from "react";
import {toast, ToastContainer} from "react-toastify";
import {connect, useDispatch, useSelector} from "react-redux";
import Routes from "../../routes/__Routes";
import ScrollTopButton from "../../components/ScrollTopButton";
import {Hoc} from "../../hoc";
import {getToken, setToken} from "../../api";
import {setTokenToStore} from "../../store/actions";

const App = (props) => {
    const {error, success} = props;
    if (error) toast.error(error);
    if (success) toast.success(success);
    const dispatch = useDispatch()
    const token = getToken()

    useEffect(() => {

        if (token) {
            dispatch(setTokenToStore(token))
        }
    }, [])

    return (
        <Fragment>
            <Hoc>
                <Routes/>
                <ToastContainer position="top-center"/>
                <ScrollTopButton/>
            </Hoc>
        </Fragment>
    );
};

const MapStateToProps = state => {
    return {
        error: state.meta.error,
        success: state.meta.success,
    }
};

export default connect(MapStateToProps)(App);
